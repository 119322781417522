import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-2b9b7501")
const _hoisted_1 = { class: "token-amount-table" }
const _hoisted_2 = { class: "token-amount-table-content items-center" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = { class: "ml-3 flex flex-col" }
const _hoisted_5 = { class: "text-lg font-medium" }
const _hoisted_6 = { class: "font-numeric" }
const _hoisted_7 = { class: "text-secondary font-numeric  text-right" }
const _hoisted_8 = { class: "value" }
const _hoisted_9 = { class: "perc" }
_popScopeId()

import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import { bnum } from '@/lib/utils';
import { TokenInfoMap } from '@/types/TokenList';

/**
 * TYPES
 */
type Props = {
  amountMap: AmountMap;
  fiatAmountMap: AmountMap;
  tokenMap: TokenInfoMap;
  fiatTotal: string;
};

type AmountMap = {
  [address: string]: string;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    amountMap: { type: Object, required: true },
    fiatAmountMap: { type: Object, required: true },
    tokenMap: { type: null, required: true },
    fiatTotal: { type: String, required: true }
  } as unknown as undefined,
  setup(__props: {
  amountMap: AmountMap;
  fiatAmountMap: AmountMap;
  tokenMap: TokenInfoMap;
  fiatTotal: string;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { fNum2 } = useNumbers();

/**
 * METHODS
 */
// The investment amount's relative percentage of the total fiat investment value.
// This has nothing to do with the pool weights.
function amountShare(address: string): string {
  return bnum(props.fiatAmountMap[address]).div(props.fiatTotal).toString();
}

return (_ctx: any,_cache: any) => {
  const _component_BalAsset = _resolveComponent("BalAsset")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.amountMap, (amount, address) => {
      return (_openBlock(), _createElementBlock("div", {
        key: address,
        class: "relative"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_BalAsset, {
              address: address,
              size: 36
            }, null, 8, ["address"]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(fNum2)(amount, _unref(FNumFormats).token)), 1),
                _createTextVNode(" " + _toDisplayString(__props.tokenMap[address].symbol), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(fNum2)(__props.fiatAmountMap[address], _unref(FNumFormats).fiat)), 1),
            _createElementVNode("div", _hoisted_9, "(" + _toDisplayString(_unref(fNum2)(amountShare(address), _unref(FNumFormats).percent)) + ")", 1)
          ])
        ])
      ]))
    }), 128))
  ]))
}
}

})