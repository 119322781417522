
import {ref, defineComponent,computed,toRef,toRefs,reactive} from 'vue';
import usePoolTransfers from '@/composables/contextual/pool-transfers/usePoolTransfers';
import { isStableLike, isStablePhantom, usePool } from '@/composables/usePool';
import useInvestState from '@/components/forms/pool_actions/InvestForm/composables/useInvestState';
import { forChange } from '@/lib/utils';
import TradeSettingsPopover, {TradeSettingsContext,} from '@/components/popovers/TradeSettingsPopover.vue';
import PoolUserStats from '@/components/contextual/pages/pool/PoolUserStats.vue';
import usePoolQuery from '@/composables/queries/usePoolQuery';
import { useRoute, useRouter } from 'vue-router';
import InvestForm from '@/components/forms/pool_actions/InvestForm/InvestForm.vue';
import InvestPreviewModal from '@/components/forms/pool_actions/InvestForm/components/InvestPreviewModal/InvestPreviewModal.vue';
import useWeb3 from '@/services/web3/useWeb3';
import ArrowLeftIcon from '@/components/_global/icons/ArrowLeftIcon.vue';
import CloseIcon from '@/components/_global/icons/CloseIcon.vue';
import QuestionIcon from '../../components/_global/icons/QuestionIcon.vue';
import TickIcon from '../../components/_global/icons/TickIcon.vue';
import StakeView from './Stake.vue';
import useStake from '@/composables/PolarisFinance/useStake';
import { TransactionResponse } from '@ethersproject/providers';
import useTransactions from '@/composables/useTransactions';
import useEthers from '@/composables/useEthers';
import useTokens from '@/composables/useTokens';
import InvestHero from '@/components/heros/InvestHero.vue';
import useBreakpoints from '@/composables/useBreakpoints';
import WithdrawForm from '@/components/forms/pool_actions/WithdrawForm/WithdrawForm.vue';
import WithdrawPreviewModal from '@/components/forms/pool_actions/WithdrawForm/components/WithdrawPreviewModal/WithdrawPreviewModal.vue';
const steps = [
  {
  "step":1,
  "headline":"Connect your wallet to unstake & withdraw",
  "button":"Connect wallet",
  },
  {
  "step":2,
  "headline":"Unstake liquidity pool tokens",
  "button":"Unstake LP Position",
  },
  {
  "step":3,
  "headline":"Withdraw your liquidity pool tokens",
  "button":"Withdraw",
  },
  {
  "step":4,
  "headline":"Preview withdrawal",
  "button":"Preview & confirmation",
  },
  {
  "step":5,
  "headline":"Everything is set and done!",
  "button":"Done",
  },
];


export default defineComponent({
  components: {
    PoolUserStats,
    WithdrawForm,
    QuestionIcon,
    ArrowLeftIcon,
    CloseIcon,
    StakeView,
    TickIcon,
    InvestHero,
    WithdrawPreviewModal,
  },
  props: {
  },
  watch: {
    isWalletReady(newValue, oldValue) {
      if (newValue==true) 
        this.activeStep=2;
    }
  },
  setup(props, { emit }) {
    const { isMobile, isDesktop } = useBreakpoints();
    const {transfersAllowed } = usePoolTransfers();
    const route = useRoute();
    const id = ref<string>(route.params.id as string);

    const poolQuery = usePoolQuery(route.params.id as string);
    const pool = computed(() => poolQuery.data.value);
    const { isStablePhantomPool } = usePool(pool);
    const {tokenAddresses,amounts, sor,sorReady} = useInvestState();
    
    const { account,isWalletReady, getProvider,startConnectWithInjectedProvider, isMismatchedNetwork } =useWeb3();
    const { addTransaction } = useTransactions();
    const { balanceFor } = useTokens();

    const investmentTokens = computed((): string[] => {
        if (isStablePhantom(pool.value!.poolType)) {
            return pool.value!.mainTokens || [];
        }
        return pool.value!.tokensList;
    });
    
    const poolQueryLoading = computed(
      () =>
        poolQuery.isLoading.value ||
        poolQuery.isIdle.value ||
        Boolean(poolQuery.error.value)
    );
    const loadingPool = computed(() => poolQueryLoading.value || !pool.value);

    
    return {
      id,
      account,
      transfersAllowed,
      sorReady,
      sor,
      pool,
      TradeSettingsContext,
      isStablePhantomPool,
      loadingPool,
      investmentTokens,
      isWalletReady,
      getProvider,
      addTransaction,
      balanceFor,
      steps,
      isDesktop,
      isMobile,
      
    };
  },
  async mounted() {
    
    if (this.pool!=undefined && this.isStablePhantomPool) {
        // Initialise SOR for batch swap queries
        this.sorReady = await this.sor.fetchPools();
    } else {
        this.sorReady = true;
    }
    const {balance, isApproved} = useStake();

    this.stakedBalance = await balance(this.pool?.address!, this.account);
    

  },
  beforeMount(){
    if(this.isWalletReady && this.activeStep==1){
        this.activeStep=2;
    }

  },
  updated() {
  },
  data() {
    return {
        activeStep:1,
        tokenAddresses:[]  as string[],
        stakedBalance: '0',
        
    };
  },
  methods: {
    setActiveStep(step){
      if(step<=steps.length)
        this.activeStep = step;
    },
    clickActiveStep(step){
      if(step!=steps.length)
        this.setActiveStep(step);
    },
    handleLPPreview(){
      this.setActiveStep(this.activeStep+1);     //THIS WILL BE OK. TESTING BELOW
    },

    handleWithdrawConfirm(){
        this.setActiveStep(this.activeStep+1);
    },
    handleUnstakeConfirmed(){
      this.setActiveStep(this.activeStep+1);
      //this.$router.push({ name: 'pool', params: { id: this.pool?.id }});
    },
    progressPerc(){
      if(this.activeStep==steps.length){
          return 1;
      }
      return (1/(steps.length-1) *(this.activeStep-1))+0.05;
    },
    goBack(){
      if(this.isWalletReady && this.activeStep==2)
        return;
      if(!this.isWalletReady && this.activeStep==1)
        return;

      this.setActiveStep(this.activeStep-1);
    },
    
  },

  emits: [],
});
