import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex items-center" }

import { computed, ref, toRef, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

// Composables
import useNumbers from '@/composables/useNumbers';
import useTokens from '@/composables/useTokens';
import { bnum } from '@/lib/utils';
import { Pool } from '@/services/pool/types';
import { TokenInfoMap } from '@/types/TokenList';

import useWithdrawalState from '../../composables/useWithdrawalState';
// Types
import { WithdrawMathResponse } from '../../composables/useWithdrawMath';
import TokenAmounts from './components/TokenAmounts.vue';
import WithdrawActions from './components/WithdrawActions.vue';
// Components
import WithdrawSummary from './components/WithdrawSummary.vue';

/**
 * TYPES
 */
type Props = {
  pool: Pool;
  math: WithdrawMathResponse;
};

type AmountMap = {
  [address: string]: string;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true },
    math: { type: null, required: true }
  } as unknown as undefined,
  emits: ["close"] as unknown as undefined,
  setup(__props: {
  pool: Pool;
  math: WithdrawMathResponse;
}, { emit }: { emit: ({
  (e: 'close'): void;
}), expose: any, slots: any, attrs: any }) {

const props = __props




/**
 * STATE
 */
const withdrawalConfirmed = ref(false);

/**
 * COMPOSABLES
 */
const { t } = useI18n();
const { getToken } = useTokens();
const { toFiat } = useNumbers();
const { fullAmounts, priceImpact, resetMath } = toRefs(props.math);
const { tokensOut, maxSlider } = useWithdrawalState(toRef(props, 'pool'));

/**
 * COMPUTED
 */
const title = computed((): string =>
  withdrawalConfirmed.value
    ? t('withdraw.preview.titles.confirmed')
    : t('withdraw.preview.titles.default')
);

const amountMap = computed((): AmountMap => {
  const amountMap = {};
  fullAmounts.value.forEach((amount, i) => {
    if (hasAmount(i)) amountMap[tokensOut.value[i]] = amount;
  });
  return amountMap;
});

const tokenMap = computed((): TokenInfoMap => {
  const tokenMap = {};
  Object.keys(amountMap.value).forEach(address => {
    tokenMap[address] = getToken(address);
  });
  return tokenMap;
});

const fiatAmountMap = computed((): AmountMap => {
  const fiatAmountMap = {};
  Object.keys(amountMap.value).forEach(address => {
    fiatAmountMap[address] = toFiat(amountMap.value[address], address);
  });
  return fiatAmountMap;
});

const fiatTotal = computed((): string =>
  Object.values(fiatAmountMap.value).reduce(
    (total, amount) => bnum(total).plus(amount).toString(),
    '0'
  )
);

/**
 * METHODS
 */
function hasAmount(index: number): boolean {
  return bnum(fullAmounts.value[index]).gt(0);
}

function handleClose(): void {
  if (withdrawalConfirmed.value) {
    resetMath.value();
    maxSlider();
  }
  emit('close');
}

return (_ctx: any,_cache: any) => {
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalCircle = _resolveComponent("BalCircle")!
  const _component_BalModal = _resolveComponent("BalModal")!

  return (_openBlock(), _createBlock(_component_BalModal, {
    show: "",
    fireworks: withdrawalConfirmed.value,
    onClose: handleClose
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (withdrawalConfirmed.value)
          ? (_openBlock(), _createBlock(_component_BalCircle, {
              key: 0,
              size: "8",
              color: "green",
              class: "mr-2 text-white"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BalIcon, { name: "check" })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createElementVNode("h4", null, _toDisplayString(_unref(title)), 1)
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(TokenAmounts, {
        amountMap: _unref(amountMap),
        tokenMap: _unref(tokenMap),
        fiatAmountMap: _unref(fiatAmountMap),
        fiatTotal: _unref(fiatTotal)
      }, null, 8, ["amountMap", "tokenMap", "fiatAmountMap", "fiatTotal"]),
      _createVNode(WithdrawSummary, {
        pool: __props.pool,
        fiatTotal: _unref(fiatTotal),
        priceImpact: _unref(priceImpact)
      }, null, 8, ["pool", "fiatTotal", "priceImpact"]),
      _createVNode(WithdrawActions, {
        pool: __props.pool,
        math: __props.math,
        class: "mt-4",
        onError: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
        onSuccess: _cache[1] || (_cache[1] = ($event: any) => (withdrawalConfirmed.value = true))
      }, null, 8, ["pool", "math"])
    ]),
    _: 1
  }, 8, ["fireworks"]))
}
}

})