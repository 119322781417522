import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-554b2482")
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "light-back pt-[16px]" }
_popScopeId()

import { computed, ref, toRef, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

// Composables
import useNumbers from '@/composables/useNumbers';
import useTokens from '@/composables/useTokens';
import { bnum } from '@/lib/utils';
import { Pool } from '@/services/pool/types';
import { TokenInfoMap } from '@/types/TokenList';

import useWithdrawalState from '../../composables/useWithdrawalState';
// Types
import { WithdrawMathResponse } from '../../composables/useWithdrawMath';
import TokenAmounts from './components/TokenAmounts.vue';
import WithdrawActions from './components/WithdrawActions.vue';
// Components
import WithdrawSummary from './components/WithdrawSummary.vue';

/**
 * TYPES
 */
type Props = {
  pool: Pool;
  math: WithdrawMathResponse;
};

type AmountMap = {
  [address: string]: string;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true },
    math: { type: null, required: true }
  } as unknown as undefined,
  emits: ["close", "confirmWithdraw"] as unknown as undefined,
  setup(__props: {
  pool: Pool;
  math: WithdrawMathResponse;
}, { emit }: { emit: ({
  (e: 'close'): void;
  (e: 'confirmWithdraw'): void;
}), expose: any, slots: any, attrs: any }) {

const props = __props




/**
 * STATE
 */
const withdrawalConfirmed = ref(false);

/**
 * COMPOSABLES
 */
const { t } = useI18n();
const { getToken } = useTokens();
const { toFiat } = useNumbers();
const { fullAmounts, priceImpact, resetMath } = toRefs(props.math);
const { tokensOut, maxSlider } = useWithdrawalState(toRef(props, 'pool'));

/**
 * COMPUTED
 */
const title = computed((): string =>
  withdrawalConfirmed.value
    ? t('withdraw.preview.titles.confirmed')
    : t('withdraw.preview.titles.default')
);

const amountMap = computed((): AmountMap => {
  const amountMap = {};
  fullAmounts.value.forEach((amount, i) => {
    if (hasAmount(i)) amountMap[tokensOut.value[i]] = amount;
  });
  return amountMap;
});

const tokenMap = computed((): TokenInfoMap => {
  const tokenMap = {};
  Object.keys(amountMap.value).forEach(address => {
    tokenMap[address] = getToken(address);
  });
  return tokenMap;
});

const fiatAmountMap = computed((): AmountMap => {
  const fiatAmountMap = {};
  Object.keys(amountMap.value).forEach(address => {
    fiatAmountMap[address] = toFiat(amountMap.value[address], address);
  });
  return fiatAmountMap;
});

const fiatTotal = computed((): string =>
  Object.values(fiatAmountMap.value).reduce(
    (total, amount) => bnum(total).plus(amount).toString(),
    '0'
  )
);

/**
 * METHODS
 */
function hasAmount(index: number): boolean {
  return bnum(fullAmounts.value[index]).gt(0);
}

function handleClose(): void {
  if (withdrawalConfirmed.value) {
    resetMath.value();
    maxSlider();
  }
  emit('close');
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(TokenAmounts, {
      amountMap: _unref(amountMap),
      tokenMap: _unref(tokenMap),
      fiatAmountMap: _unref(fiatAmountMap),
      fiatTotal: _unref(fiatTotal)
    }, null, 8, ["amountMap", "tokenMap", "fiatAmountMap", "fiatTotal"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(WithdrawSummary, {
        pool: __props.pool,
        fiatTotal: _unref(fiatTotal),
        priceImpact: _unref(priceImpact)
      }, null, 8, ["pool", "fiatTotal", "priceImpact"]),
      _createVNode(WithdrawActions, {
        pool: __props.pool,
        math: __props.math,
        class: "mt-4",
        onSuccess: _cache[0] || (_cache[0] = ($event: any) => {emit('confirmWithdraw');})
      }, null, 8, ["pool", "math"])
    ])
  ]))
}
}

})