import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, isRef as _isRef, withCtx as _withCtx, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-36c9fd28")
const _hoisted_1 = {
  key: 1,
  class: "container"
}
const _hoisted_2 = { class: "light-back px-[16px] py-[16px]" }
const _hoisted_3 = {
  key: 0,
  class: "mt-4 rounded-lg border p-2 pb-2 dark:border-gray-700"
}
const _hoisted_4 = { class: "mt-4" }
_popScopeId()

import { computed, onBeforeMount, ref, toRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';

// Components
import TokenInput from '@/components/inputs/TokenInput/TokenInput.vue';
import { isLessThanOrEqualTo, isRequired } from '@/lib/utils/validations';
import { Pool } from '@/services/pool/types';
import useWeb3 from '@/services/web3/useWeb3';

import ProportionalWithdrawalInput from './components/ProportionalWithdrawalInput.vue';
import WithdrawalTokenSelect from './components/WithdrawalTokenSelect.vue';
import WithdrawPreview from './components/WithdrawPreview/WithdrawPreview.vue';
import WithdrawTotals from './components/WithdrawTotals.vue';
import useWithdrawalState from './composables/useWithdrawalState';
// Composables
import useWithdrawMath from './composables/useWithdrawMath';

/**
 * TYPES
 */
type Props = {
  pool: Pool;
  step:Number;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true },
    step: { type: null, required: true }
  } as unknown as undefined,
  emits: ['preview','confirmWithdraw'],
  setup(__props: {
  pool: Pool;
  step:Number;
}, { emit: emits }) {

const props = __props


const showPreview = ref(false);

/**
 * COMPOSABLES
 */
const { t } = useI18n();

const {
  isProportional,
  tokenOut,
  tokenOutIndex,
  highPriceImpactAccepted,
  validInput,
  maxSlider,
  tokensOut,
  error,
  parseError,
  setError,
} = useWithdrawalState(toRef(props, 'pool'));

const withdrawMath = useWithdrawMath(
  toRef(props, 'pool'),
  isProportional,
  tokenOut,
  tokenOutIndex
);

const {
  hasAmounts,
  highPriceImpact,
  singleAssetMaxes,
  tokenOutAmount,
  tokenOutPoolBalance,
  initMath,
  loadingAmountsOut,
} = withdrawMath;

const { isWalletReady, startConnectWithInjectedProvider, isMismatchedNetwork } =
  useWeb3();

/**
 * COMPUTED
 */
const hasAcceptedHighPriceImpact = computed((): boolean =>
  highPriceImpact.value ? highPriceImpactAccepted.value : true
);

const hasValidInputs = computed(
  (): boolean => validInput.value && hasAcceptedHighPriceImpact.value
);

const singleAssetRules = computed(() => [
  isLessThanOrEqualTo(tokenOutPoolBalance.value, t('exceedsPoolBalance')),
]);

/**
 * METHODS
 */
 function onConfirmWithdraw(): void {
  emits('confirmWithdraw');
}

/**
 * WATCHERS
 */
watch(isProportional, newVal => {
  // If user selects to withdraw all tokens proportionally
  // reset the slider to max.
  if (newVal) {
    initMath();
    maxSlider();
  }
});

/**
 * CALLBACKS
 */
onBeforeMount(() => {
  isProportional.value = true;
  initMath();
  maxSlider();
});

return (_ctx: any,_cache: any) => {
  const _component_BalCheckbox = _resolveComponent("BalCheckbox")!
  const _component_BalAlert = _resolveComponent("BalAlert")!
  const _component_BalBtn = _resolveComponent("BalBtn")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (__props.step==4)
      ? (_openBlock(), _createBlock(WithdrawPreview, {
          key: 0,
          pool: __props.pool,
          math: _unref(withdrawMath),
          onClose: _cache[0] || (_cache[0] = ($event: any) => (showPreview.value = false)),
          onConfirmWithdraw: onConfirmWithdraw
        }, null, 8, ["pool", "math"]))
      : _createCommentVNode("", true),
    (__props.step==3)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_unref(isProportional))
            ? (_openBlock(), _createBlock(ProportionalWithdrawalInput, {
                key: 0,
                class: "pl-[12px] pr-[16px] py-[16px]",
                pool: __props.pool,
                tokenAddresses: _unref(tokensOut),
                math: _unref(withdrawMath)
              }, null, 8, ["pool", "tokenAddresses", "math"]))
            : (_openBlock(), _createBlock(TokenInput, {
                key: 1,
                class: "pl-[12px] pr-[16px] pb-[16px]",
                amount: _unref(tokenOutAmount),
                "onUpdate:amount": _cache[1] || (_cache[1] = ($event: any) => (_isRef(tokenOutAmount) ? tokenOutAmount.value = $event : null)),
                isValid: _unref(validInput),
                "onUpdate:isValid": _cache[2] || (_cache[2] = ($event: any) => (_isRef(validInput) ? validInput.value = $event : null)),
                name: _unref(tokenOut),
                address: _unref(tokenOut),
                disableBalance: _unref(singleAssetMaxes)[_unref(tokenOutIndex)] === '-',
                customBalance: _unref(singleAssetMaxes)[_unref(tokenOutIndex)] || '0',
                rules: _unref(singleAssetRules),
                balanceLabel: _ctx.$t('singleTokenMax'),
                balanceLoading: _unref(loadingAmountsOut),
                fixedToken: "",
                disableNativeAssetBuffer: ""
              }, {
                tokenSelect: _withCtx(() => [
                  _createVNode(WithdrawalTokenSelect, {
                    pool: __props.pool,
                    initToken: _unref(tokenOut)
                  }, null, 8, ["pool", "initToken"])
                ]),
                _: 1
              }, 8, ["amount", "isValid", "name", "address", "disableBalance", "customBalance", "rules", "balanceLabel", "balanceLoading"])),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(WithdrawTotals, {
              math: _unref(withdrawMath),
              class: "mt-4"
            }, null, 8, ["math"]),
            (_unref(highPriceImpact))
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_BalCheckbox, {
                    modelValue: _unref(highPriceImpactAccepted),
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_isRef(highPriceImpactAccepted) ? highPriceImpactAccepted.value = $event : null)),
                    rules: [_unref(isRequired)(_ctx.$t('priceImpactCheckbox'))],
                    name: "highPriceImpactAccepted",
                    size: "sm",
                    label: _ctx.$t('priceImpactAccept', [_ctx.$t('withdrawing')])
                  }, null, 8, ["modelValue", "rules", "label"])
                ]))
              : _createCommentVNode("", true),
            (_unref(error) !== null)
              ? (_openBlock(), _createBlock(_component_BalAlert, {
                  key: 1,
                  type: "error",
                  title: _unref(parseError)(_unref(error)).title,
                  description: _unref(parseError)(_unref(error)).description,
                  class: "mt-4",
                  block: "",
                  actionLabel: "Dismiss",
                  onActionClick: _cache[4] || (_cache[4] = ($event: any) => (_unref(setError)(null)))
                }, null, 8, ["title", "description"]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_4, [
              (!_unref(isWalletReady))
                ? (_openBlock(), _createBlock(_component_BalBtn, {
                    key: 0,
                    label: _ctx.$t('connectWallet'),
                    color: "gradient",
                    block: "",
                    onClick: _unref(startConnectWithInjectedProvider)
                  }, null, 8, ["label", "onClick"]))
                : (_openBlock(), _createBlock(_component_BalBtn, {
                    key: 1,
                    label: _ctx.$t('preview'),
                    color: "gradient",
                    disabled: 
            !_unref(hasAmounts) ||
            !_unref(hasValidInputs) ||
            _unref(isMismatchedNetwork) ||
            _unref(loadingAmountsOut)
          ,
                    block: "",
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (emits('preview')))
                  }, null, 8, ["label", "disabled"]))
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})