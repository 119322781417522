import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-8fa67bc6")
const _hoisted_1 = { class: "summary-table" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "summary-table-label flex-1" }
const _hoisted_4 = { class: "summary-table-label flex-1 text-right" }
const _hoisted_5 = { class: "flex" }
const _hoisted_6 = { class: "flex-1" }
const _hoisted_7 = { class: "summary-table-number flex-1" }
_popScopeId()

import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import useUserSettings from '@/composables/useUserSettings';
import { Pool } from '@/services/pool/types';

/**
 * TYPES
 */
type Props = {
  pool: Pool;
  fiatTotal: string;
  priceImpact: number;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true },
    fiatTotal: { type: String, required: true },
    priceImpact: { type: Number, required: true }
  } as unknown as undefined,
  setup(__props: {
  pool: Pool;
  fiatTotal: string;
  priceImpact: number;
}) {



/**
 * COMPOSABLES
 */
const { fNum2 } = useNumbers();
const { currency } = useUserSettings();

return (_ctx: any,_cache: any) => {
  const _component_BalTooltip = _resolveComponent("BalTooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createTextVNode(_toDisplayString(_ctx.$t('total')) + " ", 1),
        _createVNode(_component_BalTooltip, {
          text: _ctx.$t('tooltips.invest.total', [_unref(currency).toUpperCase()]),
          iconSize: "xs",
          class: "ml-1 inline"
        }, null, 8, ["text"])
      ]),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(fNum2)(__props.fiatTotal, _unref(FNumFormats).fiat)), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createTextVNode(_toDisplayString(_ctx.$t('priceImpact')) + " ", 1),
        _createVNode(_component_BalTooltip, {
          text: _ctx.$t('tooltips.invest.priceImpact'),
          iconSize: "xs",
          width: "50",
          class: "ml-1 inline"
        }, null, 8, ["text"])
      ]),
      _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(fNum2)(__props.priceImpact, _unref(FNumFormats).percent)), 1)
    ])
  ]))
}
}

})